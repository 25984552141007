import React from "react"
import { graphql, Link } from "gatsby"
import Moment from 'react-moment';
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Pagination } from "../components/pagination"
import tottori_suica from "../svg/tottori_suica.svg"
import button_event from "../images/Group.png"

const BlogPage = ({ data, pageContext }) => {
  return (
    <Layout>
      <Seo title="イベント情報・お知らせ" />

      <section className="p-16">
        <div className="container max-w-5xl mx-auto">
          <div className="w-full flex justify-around">
            <div className="flex">
              <div className="hidden md:block">
                <img src={button_event} width="120" />
              </div>
              <h1 className="py-4 text-2xl md:text-4xl text-base-dark font-bold leading-none">イベント情報・お知らせ</h1>
              <div className="hidden md:block px-4">
                {/* <StaticImage src="../svg/tottori_suica.svg" alt="" width={48} /> */}
                <img src={tottori_suica} width="48" className="py-6" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-base-main px-8">
        <div className="max-w-5xl mx-auto">

          <div className="flex flex-wrap">
            <div className="">
              <Link to={`/`}>
                <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
              </Link>
              <span className="mx-4 text-sm">></span>
              <Link to={`/news/1`}>
                <button className="hover:text-base-dark text-base-dark text-sm underline hover:no-underline">イベント情報・お知らせ</button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-base-main p-8">
        <div className="container max-w-5xl mx-auto">

          <div className="py-6 px-4 border-2 border-base-dark bg-white rounded-lg">
            {data.allMicrocmsPortalNews.edges.map(({ node }) => (
              <div className="border-b py-4" key={node.portalNewsId}>
                <div className="text-md text-base-dark">
                  <Moment className="text-md" format="YYYY.MM.DD">{node.createdAt}</Moment>
                  <span className="bg-base-dark rounded-full font-bold text-md text-white mx-2 px-4 py-1">
                    <span className="text-xs">{node.category}</span>
                  </span>
                </div>
                <div className="py-2">
                  <Link to={`/news-detail/${node.portalNewsId}`} className="font-bold text-md hover:underline">
                    {node.title}
                  </Link>
                </div>
              </div>
            ))}
          </div>

        </div>
      </section>

      <section className="flex flex-col items-center px-4">
        <div className="max-w-5xl mx-auto m-8">
          <div className="flex flex-wrap">
            <div className="w-full py-6 flex">
              {(() => {
                if (!pageContext.isFirst) {
                  return (
                    <Link to={`/news/${pageContext.currentPage - 1}`} rel="next" className="px-2">
                      <button className="rounded-full text-white bg-secondry-main hover:opacity-60 w-10 h-10">
                        ←
                      </button>
                    </Link>
                  )
                }
              })()}
              <Pagination page="news" totalCount={data.allMicrocmsPortalNews.totalCount} />
              {(() => {
                if (!pageContext.isLast) {
                  return (
                    <Link to={`/news/${pageContext.currentPage + 1}`} rel="next" className="px-2">
                      <button className="rounded-full text-white bg-secondry-main hover:opacity-60 w-10 h-10">
                        →
                      </button>
                    </Link>
                  )
                }
              })()}
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query($limit: Int!, $skip: Int!) {
    allMicrocmsPortalNews(sort: { fields: [createdAt], order: ASC }, limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          title
          category
          portalNewsId
          updatedAt
          createdAt
        }
      }
    }
  }
`
